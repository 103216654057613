import { ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import "./assets/scss/service-create.scss";
import { PropsApp } from "./models/props/PropsApp";
import { StepperComponent } from "./pages/StepperPage";
import { store } from "./store/store";

const App = ({
  leadUserEmail,
  serviceAccountId,
  onCloseModal,
  userId,
  seeInformation,
  service = null,
  estate = null,
  frontiers = null,
}: PropsApp) => {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <StepperComponent
          leadUserEmail={leadUserEmail}
          serviceAccountId={serviceAccountId}
          onCloseModal={onCloseModal}
          userId={userId}
          service={service}
          seeInformation={seeInformation}
          estate={estate}
          frontiers={frontiers}
        />
      </ThemeConfig>
    </Provider>
  );
};

export default App;
