import {
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
  theme,
} from "@enerbit/base";
import * as singleSpa from "single-spa";
import { StepFormType } from "../estate-information/EstateInformation";

const IdsInformation = ({
  formik,
  index,
  service,
  disabled,
  frontiers,
}: StepFormType) => (
  <>
    <Typography
      variant="h6"
      fontWeight="bold"
      sx={{ color: theme.palette.primary.main, marginTop: "44px" }}
    >
      {index}. ID's
    </Typography>
    <Grid container spacing={2} sx={{ marginTop: "20px" }}>
      <Grid item md={4} xs={12}>
        <FormGroup>
          <InputLabel shrink className="Input-label">
            ID del servicio de energía
          </InputLabel>
          <TextField
            disabled={disabled}
            fullWidth
            className="TextField-without-border-radius"
            id="address"
            name="address"
            variant="outlined"
            value={service?.id}
            type="text"
          />
        </FormGroup>
      </Grid>
      <Grid item md={4} xs={12}>
        <FormGroup>
          <InputLabel shrink className="Input-label">
            ID del service account
          </InputLabel>
          <TextField
            disabled={disabled}
            fullWidth
            className="TextField-without-border-radius"
            id="address"
            name="address"
            variant="outlined"
            value={service?.service_account_id}
            type="text"
          />
        </FormGroup>
      </Grid>
      <Grid item md={4} xs={12}>
        <FormGroup
          onClick={() =>
            singleSpa.navigateToUrl(`/#/states/${service?.estate.id}`)
          }
        >
          <InputLabel shrink className="Input-label">
            ID de la oportunidad
          </InputLabel>
          <TextField
            disabled={disabled}
            fullWidth
            className="TextField-without-border-radius"
            id="address"
            name="address"
            variant="outlined"
            value={service?.estate.id}
            type="text"
          />
        </FormGroup>
      </Grid>
    </Grid>
    <Grid container spacing={2} sx={{ marginTop: "20px" }}>
      <Grid item md={6} xs={12}>
        <FormGroup>
          <InputLabel shrink className="Input-label">
            Meter ID
          </InputLabel>
          <TextField
            disabled={disabled}
            fullWidth
            className="TextField-without-border-radius"
            id="address"
            name="address"
            variant="outlined"
            value={service?.meter?.id ?? "SIN MEDIDOR"}
            type="text"
          />
        </FormGroup>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormGroup>
          <InputLabel shrink className="Input-label">
            Frontier ID
          </InputLabel>
          <TextField
            disabled={disabled}
            fullWidth
            className="TextField-without-border-radius"
            id="address"
            name="address"
            variant="outlined"
            value={frontiers?.id ?? "NO ES FRONTERA"}
            type="text"
          />
        </FormGroup>
      </Grid>
    </Grid>
  </>
);

export default IdsInformation;
