import { Box, Typography, enerbitColors } from "@enerbit/base";
import bombiWorried from "../../assets/images/bombi_worried.png";

export type BombiProps = {
  message: string;
};
export const Bombi = ({ message }: BombiProps) => {
  return (
    <Box className="center-items box-bombi">
      <Box
        sx={{
          width: "135px",
        }}
        component="img"
        alt="Bombi"
        src={bombiWorried}
      />
      <Typography
        variant="h6"
        sx={{
          marginTop: "60px",
        }}
        color={enerbitColors.neutral.main}
      >
        {message}
      </Typography>
    </Box>
  );
};
