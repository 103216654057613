import { Typography, theme } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikType, StringUtils, handleTextFieldChanged } from "../../common/Utils";
import { CreateServiceFormType } from "../../models/form/CreateServiceTypeForm";
import { Frontier } from "../../models/frontier/frontier";
import { Service } from "../../models/props/PropsApp";
import { StoreState } from "../../models/states/StoreState";
import { getSocialStratums } from "../../store/actions/accounts/accounts.actions";
import { getCatastralTypes } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { getCities, getCountries, getStates } from "../../store/actions/geopolitics/geopolitics.actions";
import { onEstateInformationAutoCompleteChanged } from "../../store/slices/createServiceSlice";
import { AppDispatch } from "../../store/store";
import { LeadContent } from "./components/LeadContent";

export type StepFormType = {
  formik: FormikType<CreateServiceFormType>;
  index: number;
  service?: Service | null;
  disabled?: boolean;
  frontiers?: Frontier | null;
};
const EstateInformationForm = ({ formik, index, disabled }: StepFormType) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    paginationDataCountries,
    paginationDataStates,
    paginationDataCities,
    paginationSocialStratum,
    catastralTypes,
    isLoadingAutocompleteInfo,
    autocompleteInfo,
    estateInformationAutoCompleted,
  } = useSelector((state: StoreState) => state.createServiceForm);

  const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();

  const [selectedState, setSelectedState] = useState<string | undefined>();
  const [selectedCity, setSelectedCity] = useState<string | undefined>();
  const [selectedCatastralType, setSelectedCatastralType] = useState<string | undefined>();
  const [selectedSocialStratum, setSelectedSocialStratum] = useState<string | undefined>();

  useEffect(() => {
    if (!isLoadingAutocompleteInfo && autocompleteInfo && !estateInformationAutoCompleted) {
      dispatch(onEstateInformationAutoCompleteChanged(true));
      const { estate_information } = autocompleteInfo;

      // Estate information
      const country = paginationDataCountries?.items.find((country) => country.iso2 == estate_information?.country);
      if (country) {
        setSelectedCountryId(country.id);
      } else {
        const country = paginationDataCountries?.items.find((country) =>
          country.name.toLowerCase().includes("colombia")
        );
        if (country) {
          setSelectedCountryId(country.id);
        }
      }
      if (estate_information?.department) {
        setSelectedState(estate_information.department);
      }
      if (estate_information?.city) {
        setSelectedCity(estate_information.city);
      }
      if (estate_information?.address) {
        handleTextFieldChanged(estate_information.address, "address", formik);
      }
      if (estate_information?.social_stratum) {
        setSelectedSocialStratum(estate_information.social_stratum);
      }
      const geolocalization = estate_information?.geolocalization;
      if (geolocalization) {
        if (geolocalization.altitude) {
          handleTextFieldChanged(geolocalization.altitude, "altitude", formik);
        }
        if (geolocalization.longitude) {
          handleTextFieldChanged(geolocalization.longitude, "longitude", formik);
        }
        if (geolocalization.latitude) {
          handleTextFieldChanged(geolocalization.latitude, "latitude", formik);
        }
        if (geolocalization.plus_code) {
          handleTextFieldChanged(geolocalization.plus_code, "plusCode", formik);
        }
      }
      if (estate_information?.cadastral_id) {
        handleTextFieldChanged(estate_information.cadastral_id, "catastralIdentifier", formik);
      }
      if (estate_information?.cadastral_type_sui_code) {
        setSelectedCatastralType(estate_information.cadastral_type_sui_code);
      }
    }
  }, [autocompleteInfo]);

  // Countries
  useEffect(() => {
    if (!paginationDataCountries || paginationDataCountries.items.length == 0) {
      dispatch(getCountries());
    }
  }, [paginationDataCountries]);

  useEffect(() => {
    if (paginationDataCountries && paginationDataCountries.items.length > 0 && selectedCountryId) {
      handleTextFieldChanged(selectedCountryId, "selectedCountryId", formik);
    }
  }, [selectedCountryId, paginationDataCountries]);

  // States
  useEffect(() => {
    if (formik.values.selectedCountryId && formik.values.selectedCountryId != "") {
      dispatch(getStates(formik.values.selectedCountryId));
    }
  }, [formik.values.selectedCountryId]);

  useEffect(() => {
    if (paginationDataStates && paginationDataStates.items.length > 0 && selectedState) {
      const state = paginationDataStates.items.find((state) => state.name.toLowerCase() == selectedState.toLowerCase());
      if (state) {
        handleTextFieldChanged(state.id, "selectedStateId", formik);
      }
    }
  }, [selectedState, paginationDataStates]);

  // Cities
  useEffect(() => {
    if (formik.values.selectedStateId && formik.values.selectedStateId != "") {
      dispatch(getCities(formik.values.selectedStateId));
    }
  }, [formik.values.selectedStateId]);

  useEffect(() => {
    if (paginationDataCities && paginationDataCities.items.length > 0 && selectedCity) {
      const city = paginationDataCities.items.find(
        (city) => StringUtils.normalize(city.name.toLowerCase()) == StringUtils.normalize(selectedCity.toLowerCase())
      );
      if (city) {
        handleTextFieldChanged(city.id, "selectedCityId", formik);
      }
      setSelectedCity(undefined);
    }
  }, [selectedCity, paginationDataCities]);

  // Social Stratum
  useEffect(() => {
    if (!paginationSocialStratum || paginationSocialStratum.items.length == 0) {
      dispatch(getSocialStratums());
    }
  }, []);

  useEffect(() => {
    if (paginationSocialStratum && paginationSocialStratum.items.length > 0 && selectedSocialStratum) {
      const socialStratum = paginationSocialStratum.items.find(
        (socialStratum) => socialStratum.code == selectedSocialStratum
      );
      if (socialStratum) {
        handleTextFieldChanged(socialStratum.id, "socialStratumId", formik);
      }
      setSelectedSocialStratum(undefined);
    }
  }, [selectedSocialStratum, paginationSocialStratum]);

  // Catastral types
  useEffect(() => {
    if (!catastralTypes || catastralTypes.length == 0) {
      dispatch(getCatastralTypes());
    }
  }, []);

  useEffect(() => {
    if (catastralTypes && catastralTypes.length > 0 && selectedCatastralType) {
      const catastralType = catastralTypes.find((catastralType) => catastralType.sui_code == selectedCatastralType);
      if (catastralType) {
        handleTextFieldChanged(catastralType.id, "catastralTypeId", formik);
      }
      setSelectedCatastralType(undefined);
    }
  }, [selectedCatastralType, catastralTypes]);

  return (
    <>
      <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.primary.main, marginTop: "44px" }}>
        {index}. Información de la oportunidad
      </Typography>
      <LeadContent formik={formik} disabled={disabled} />
    </>
  );
};

export default EstateInformationForm;
