import { Grid, InfoIcon, Typography, theme } from "@enerbit/base";
import { useSelector } from "react-redux";
import { StoreState } from "../../models/states/StoreState";
import { StepFormType } from "../estate-information/EstateInformation";

export const EstateSelection = ({ formik, index }: StepFormType) => {
  const { paginationLeads } = useSelector(
    (state: StoreState) => state.createServiceForm
  );

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <InfoIcon color="secondary" sx={{ marginRight: "12px" }} />
        <Typography
          variant="h6"
          sx={{ color: theme.palette.primary.main, width: "95%" }}
        >
          La información autocompletada en los campos siguientes, fue importada
          de la que se diligenció anteriormente en la documentación.
        </Typography>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ color: theme.palette.primary.main, marginTop: "44px" }}
      >
        {index}. Información de la oportunidad
      </Typography>
    </>
  );
};
