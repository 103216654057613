import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Frontier } from "../../../models/frontier/frontier";

export type GetFrontierProps = {
  lead_id?: string;
  measurement_point_id?: string;
};
export const getFrontier = createAsyncThunk(
  //action type string
  "[Assignees] Get Frontier",
  //callback function
  async (payload: GetFrontierProps, _thunkAPI): Promise<EnerbitPagination<Frontier>> => {
    const { data, status } = await api.get(`assignees/frontiers`, {
      params: {
        ...payload,
        page: 0,
        size: 50,
      },
    });
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
