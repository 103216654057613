import {
  AdapterDayjs,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  DesktopDatePicker,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  Switch,
  TextField,
  Typography,
  theme,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleTextFieldChanged } from "../../common/Utils";
import { StoreState } from "../../models/states/StoreState";
import { getEnerbitElectricitySupplyServiceAgreements } from "../../store/actions/accounts/accounts.actions";
import {
  getElectricitySupplyServiceAgreement,
  getServiceStatus,
} from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { AppDispatch } from "../../store/store";
import { StepFormType } from "../estate-information/EstateInformation";
import { CardFijabit } from "./components/CardFijabit";

const ContractInformationForm = ({ formik, index, disabled }: StepFormType) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoadingProviders,
    paginationServiceProviders,
    paginationEnerbitElectricitySupplyServiceAgreements,
    paginationElectricitySupplyServiceAgreement,
    isLoadingServiceStatus,
    serviceStatus,
    isLoadingAutocompleteInfo,
    contractInformationAutoCompleted,
  } = useSelector((state: StoreState) => state.createServiceForm);

  const [selectedProvider, setSelectedProvider] = useState<
    string | undefined
  >();

  // useEffect(() => {
  //   if (!isLoadingAutocompleteInfo && autocompleteInfo && !contractInformationAutoCompleted) {
  //     dispatch(onContractInformationAutoCompleteChanged(true));
  //     const { contract_information } = autocompleteInfo;

  //     if (contract_information?.electricity_supply_service_started_at && !formik.values.frontierRegisterDate) {
  //       handleTextFieldChanged(
  //         moment(contract_information.electricity_supply_service_started_at),
  //         "frontierRegisterDate",
  //         formik
  //       );
  //     }
  //     if (
  //       contract_information?.enerbit_electricity_supply_service_started_at &&
  //       !formik.values.dateStartEnerbitService
  //     ) {
  //       handleTextFieldChanged(
  //         moment(contract_information.enerbit_electricity_supply_service_started_at),
  //         "dateStartEnerbitService",
  //         formik
  //       );
  //     }
  //     if (contract_information?.provider?.nit) {
  //       setSelectedProvider(contract_information.provider.nit);
  //     }
  //   }
  // }, [autocompleteInfo]);

  useEffect(() => {
    if (
      paginationServiceProviders &&
      paginationServiceProviders.items.length > 0 &&
      selectedProvider
    ) {
      const provider = paginationServiceProviders.items.find(
        (provider) => provider.nit == selectedProvider
      );
      if (provider) {
        handleTextFieldChanged(provider.id, "serviceProviderId", formik);
      }
      setSelectedProvider(undefined);
    }
  }, [selectedProvider, paginationServiceProviders]);

  // Enerbit electricity service agreements
  useEffect(() => {
    if (
      !paginationEnerbitElectricitySupplyServiceAgreements ||
      paginationEnerbitElectricitySupplyServiceAgreements.items.length == 0
    ) {
      dispatch(getEnerbitElectricitySupplyServiceAgreements());
    }
  }, []);

  useEffect(() => {
    if (
      !paginationElectricitySupplyServiceAgreement ||
      paginationElectricitySupplyServiceAgreement.items.length == 0
    ) {
      dispatch(getElectricitySupplyServiceAgreement());
    }
  }, []);

  useEffect(() => {
    if (!serviceStatus || serviceStatus.length == 0) {
      dispatch(getServiceStatus());
    }
  }, []);

  useEffect(() => {}, [formik.values.dateStartEnerbitService]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ color: theme.palette.primary.main, marginTop: "44px" }}
      >
        {index}. Información de contrato
      </Typography>
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel shrink className="Input-label">
          Proveedor del servicio
        </InputLabel>
        <TextField
          fullWidth
          select
          className="TextField-without-border-radius"
          id="serviceProviderId"
          name="serviceProviderId"
          disabled={disabled}
          variant="outlined"
          value={
            paginationServiceProviders?.items?.some(
              (provider) => provider.id === formik.values.serviceProviderId
            )
              ? formik.values.serviceProviderId
              : ""
          }
          onChange={formik.handleChange}
          error={
            formik.touched.serviceProviderId &&
            Boolean(formik.errors.serviceProviderId)
          }
          type="text"
        >
          {isLoadingProviders ? (
            <CircularProgress />
          ) : (paginationServiceProviders?.items?.length ?? 0) > 0 ? (
            (paginationServiceProviders?.items ?? []).map((serviceProvider) => (
              <MenuItem key={serviceProvider.id} value={serviceProvider.id}>
                {serviceProvider.name}
              </MenuItem>
            ))
          ) : (
            <Typography variant="body1">
              No hemos encontrado ningún resultado
            </Typography>
          )}
        </TextField>
      </FormGroup>
      <FormGroup>
        <InputLabel shrink className="Input-label">
          Tipo de contrato para el servicio de electricidad
        </InputLabel>
        <TextField
          disabled={disabled}
          fullWidth
          select
          className="TextField-without-border-radius"
          id="electricitySupplyServiceContractType"
          name="electricitySupplyServiceContractType"
          variant="outlined"
          value={
            paginationElectricitySupplyServiceAgreement?.items.some(
              (agreement) =>
                agreement.id ===
                formik.values.electricitySupplyServiceContractType
            )
              ? formik.values.electricitySupplyServiceContractType
              : ""
          }
          helperText={
            formik.touched.electricitySupplyServiceContractType &&
            formik.errors.electricitySupplyServiceContractType
          }
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(
              e.target.value,
              "electricitySupplyServiceContractType",
              formik
            );
          }}
          error={
            formik.touched.electricitySupplyServiceContractType &&
            Boolean(formik.errors.electricitySupplyServiceContractType)
          }
          type="text"
        >
          {isLoadingProviders ? (
            <CircularProgress />
          ) : (
            paginationElectricitySupplyServiceAgreement?.items.map(
              (electricitySupplyServiceAgreement) => {
                return (
                  <MenuItem
                    key={electricitySupplyServiceAgreement.id}
                    value={electricitySupplyServiceAgreement.id}
                  >
                    {electricitySupplyServiceAgreement.name}
                  </MenuItem>
                );
              }
            ) ?? (
              <Typography variant="body1">
                No hemos encontrado ningún resultado
              </Typography>
            )
          )}
        </TextField>
      </FormGroup>
      <FormGroup sx={{ marginTop: "26px" }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              id="cuttable"
              name="cuttable"
              checked={formik.values.cuttable}
              onChange={formik.handleChange}
              color="secondary"
            />
          }
          label="¿El servicio es desconectable?"
        />
      </FormGroup>
      <FormGroup sx={{ marginTop: "24px" }}>
        <InputLabel shrink className="Input-label">
          Estado del servicio
        </InputLabel>
        <TextField
          disabled={disabled}
          fullWidth
          select
          className="TextField-without-border-radius"
          id="serviceStatusId"
          name="serviceStatusId"
          variant="outlined"
          value={
            serviceStatus.some(
              (status) => status.id === formik.values.serviceStatusId
            )
              ? formik.values.serviceStatusId
              : ""
          }
          helperText={
            formik.touched.serviceStatusId && formik.errors.serviceStatusId
          }
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "serviceStatusId", formik);
          }}
          error={
            formik.touched.serviceStatusId &&
            Boolean(formik.errors.serviceStatusId)
          }
          type="text"
        >
          {isLoadingServiceStatus ? (
            <CircularProgress />
          ) : serviceStatus.length > 0 ? (
            serviceStatus.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.description}
              </MenuItem>
            ))
          ) : (
            <Typography variant="body1">
              No hemos encontrado ningún resultado
            </Typography>
          )}
        </TextField>
      </FormGroup>

      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ marginTop: "44px" }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          ¿El servicio de electricidad tendra servicios enerBit?
        </Typography>
        <Switch
          disabled={disabled}
          id="willHaveEnerbitServices"
          name="willHaveEnerbitServices"
          checked={formik.values.willHaveEnerbitServices}
          onChange={(_, checked) => {
            handleTextFieldChanged(checked, "willHaveEnerbitServices", formik);
          }}
          color="secondary"
        />
      </Grid>
      {formik.values.willHaveEnerbitServices && (
        <Box>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Fecha de inicio del servicio enerBit
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                onChange={(value: any) => {
                  if (value) {
                    handleTextFieldChanged(
                      moment(value),
                      "dateStartEnerbitService",
                      formik
                    );
                  }
                }}
                value={formik.values.dateStartEnerbitService}
                disabled
                slotProps={{
                  textField: {
                    inputProps: {
                      placeholder: "De",
                      type: "text",
                    },
                    sx: { width: "100%" },
                    disabled: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormGroup>
          {paginationEnerbitElectricitySupplyServiceAgreements && (
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Plan para el servicio enerBit
              </InputLabel>
              <Autocomplete
                disabled={disabled}
                id="enerbitServicePlan"
                fullWidth
                options={
                  paginationEnerbitElectricitySupplyServiceAgreements?.items ??
                  []
                }
                value={
                  paginationEnerbitElectricitySupplyServiceAgreements?.items.find(
                    (value) => value.id == formik.values.enerbitServicePlanId
                  ) || null
                }
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(_e, value) => {
                  if (value) {
                    handleTextFieldChanged(
                      value.id,
                      "enerbitServicePlanId",
                      formik
                    );
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <CardFijabit
                      key={option.id}
                      electricitySupplyServiceAgreement={option}
                      renderInputProps={props}
                    />
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="enerbitServicePlanId"
                    className="TextField-without-border-radius"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormGroup>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ContractInformationForm;
