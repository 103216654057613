import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Lead } from "../../../models/lead/leads";

export const getLeads = createAsyncThunk(
  //action type string
  "[LEADS] Get Leads",
  //callback function
  async (email: string, _thunkAPI): Promise<EnerbitPagination<Lead>> => {
    const leads = await api.get(`leads/`, {
      params: {
        has_service: false,
        email: email,
        size: 400,
        page: 1,
      },
    });
    return leads.data;
  }
);
