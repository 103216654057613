import { Button, IconButton, enerbitColors } from "@enerbit/base";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import "../../assets/scss/modal-enerbit.scss";
export type ModalEnerbitESSProps = {
  open: boolean;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onOk: () => void;
};

export const ModalEnerbitESS = ({
  open,
  onClose,
  onOk,
}: ModalEnerbitESSProps) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-confirm-enerbit-ess ">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={(_) => onClose({}, "backdropClick")}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            color={enerbitColors.primary.main}
            align="center"
          >
            ¿Estás seguro que este servicio no contará con servicios enerBit?
          </Typography>
          <Box className="box-buttons">
            <Button
              sx={{
                color: enerbitColors.neutral.main,
              }}
              onClick={(_) => onClose({}, "backdropClick")}
            >
              Me equivoqué
            </Button>
            <Button variant="contained" color="secondary" onClick={onOk}>
              Estoy seguro
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
