import {
  AssetOwnerShip,
  AutoGeneratorType,
  CatrastalType,
  ElectricitySupplyService,
  ExportEnergy,
  ExportEnergyGenerationType,
  PortafolioRiskUserType,
  QualityGroup,
  ServiceStatus,
  SpecialAreaType,
  SpecialCondition,
  SubsistenceConsumption,
  SuiSocialStratum,
  TensionLevel,
  ZoneSui,
  api,
} from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteInputsUpdate } from "../../../common/FormUtils";
import { AutoCompleteInfo } from "../../../models/autocomplete-info/AutoCompleteInfo";
import {
  ElectricitySupplyCreate,
  UpdateElectricitySupply,
} from "../../../models/electricity-supply-create/electricity-supply-create";

export const getSuiSocialStratums = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get SUI Social Stratums",
  //callback function
  async (_payload, _thunkAPI): Promise<SuiSocialStratum[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/sui-social-stratums/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getCatastralTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Catastral Types",
  //callback function
  async (_payload, _thunkAPI): Promise<CatrastalType[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/catastral-types/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getSubsistenceConsumptions = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Subsistence consumptions",
  //callback function
  async (_payload, _thunkAPI): Promise<SubsistenceConsumption[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/subsistence-consumptions/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getPortafolioRiskUserTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Portafolio Risk User Types",
  //callback function
  async (_payload, _thunkAPI): Promise<PortafolioRiskUserType[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/portafolio-risk-user-types/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getQualityGroups = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Quality groups",
  //callback function
  async (_payload, _thunkAPI): Promise<QualityGroup[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/quality-groups/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getZonesSui = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Zones SUI",
  //callback function
  async (_payload, _thunkAPI): Promise<ZoneSui[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/zones-sui/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getSpecialConditions = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Special Conditions",
  //callback function
  async (_payload, _thunkAPI): Promise<SpecialCondition[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/special-conditions/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getSpecialAreaTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Special Area Types",
  //callback function
  async (_payload, _thunkAPI): Promise<SpecialAreaType[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/special-area-types/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getAutocompleteEssCreationInfo = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Autocomplete ESS Creation Info",
  //callback function
  async (payload: string, _thunkAPI): Promise<AutoCompleteInfo> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/autocomplete-ess-creation/${payload}`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getElectricitySupplyServiceAgreement = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Electricity Supply Service Agreements",
  //callback function
  async (_payload, _thunkAPI): Promise<AutoCompleteInfo> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/electricity-supply-service-agreements/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getAutoGeneratorTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Autogenerator Types",
  //callback function
  async (_payload, _thunkAPI): Promise<AutoGeneratorType[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/autogenerator-types/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getExportEnergyGenerationTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Export Energy Generation Types",
  //callback function
  async (_payload, _thunkAPI): Promise<ExportEnergyGenerationType[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/export-energy-generation-types/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getServiceStatus = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Service Status",
  //callback function
  async (_payload, _thunkAPI): Promise<ServiceStatus[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/electricity-supply-services/services-status`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const createElectricitySupplyService = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Create Electricity Supply Service",
  //callback function
  async (
    payload: ElectricitySupplyCreate,
    _thunkAPI
  ): Promise<ElectricitySupplyService> => {
    const { data, status } = await api.post(
      `electricity-supply-service/manager/electricity-supply-service/`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export type GetElectricitySupplyServiceProps = {
  measurement_point_id?: string;
};
export const getElectricitySupplyService = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Electricity Supply Service",
  //callback function
  async (
    payload: GetElectricitySupplyServiceProps,
    _thunkAPI
  ): Promise<ElectricitySupplyService> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/electricity-supply-services/`,
      {
        params: payload,
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getExportEnergy = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Export Energy",
  //callback function
  async (_payload, _thunkAPI): Promise<ExportEnergy[]> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/export-energy/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getAssetOwnership = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Asset Ownership",
  //callback function
  async (_payload, _thunkAPI): Promise<AssetOwnerShip[]> => {
    const { data, status } = await api.get(
      `/electricity-supply-service/manager/asset-ownerships/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getTensionLevel = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Tension level",
  //callback function
  async (_payload, _thunkAPI): Promise<TensionLevel[]> => {
    const { data, status } = await api.get(
      `/electricity-supply-service/manager/voltage-levels/`
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const createEnerbitElectricitySupplyService = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Create Enerbit Electricity Supply Service",
  //callback function
  async (
    payload: EnerbitElectricitySupplyServiceCreate,
    _thunkAPI
  ): Promise<any> => {
    const { data, status } = await api.post(
      `electricity-supply-service/manager/enerbit-electricity-supply-service`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const updateElectricitySupply = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Update creg subscribers",
  //callback function
  async (payload: UpdateElectricitySupply, _thunkAPI) => {
    console.log(payload, "payload");
    await _thunkAPI.dispatch(updateCregSubscribers(payload));
    await _thunkAPI.dispatch(updateEstates(payload));
  }
);

export const updateCregSubscribers = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Update creg suscribers",
  //callback function
  async (
    payload: UpdateElectricitySupply,
    _thunkAPI
  ): Promise<ElectricitySupplyService> => {
    const { data, status } = await api.patch(
      `electricity-supply-service/manager/creg-subscribers/${payload.id}/`,
      deleteInputsUpdate(payload.creg_subscriber),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const updateEstates = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Update estates",
  //callback function
  async (
    payload: UpdateElectricitySupply,
    _thunkAPI
  ): Promise<ElectricitySupplyService> => {
    const { data, status } = await api.patch(
      `accounts/estates/${payload.id_estate}/`,
      payload.estate,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export interface EnerbitElectricitySupplyServiceCreate {
  user_id: string;
  electricity_supply_service_id: string;
  cuttable: boolean;
  service_status_id: string;
  started_at: Date;
  ended_at: Date | null;
  service_agreement_id: string;
}
