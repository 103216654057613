export interface Frontier {
  frontier_xm_code: string;
  frontier_xm_registered_from: Date;
  lead_id: string;
  id: string;
  created_at: Date;
  updated_at?: Date;
  frontier_type: importExportStatus.ExportFrontier | importExportStatus.ImportFrontier;
}

export enum importExportStatus {
  ExportFrontier = "export_frontier",
  ImportFrontier = "import_frontier",
}
