import { Box, Typography, enerbitColors } from "@enerbit/base";

export type CardTitleDynamicTableProps = {
  title: string;
};

const CardTitleDynamicTable = ({ title }: CardTitleDynamicTableProps) => {
  return (
    <Box
      sx={{
        backgroundColor: enerbitColors.neutral[100],
        padding: "12px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="body1" textAlign="center" fontWeight="bold">
        {title}
      </Typography>
    </Box>
  );
};

export default CardTitleDynamicTable;
