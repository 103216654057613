import { yup } from "@enerbit/base";

export const validationSchema = yup.object().shape({
  selectedCountryId: yup.string().required("El país es requerido"),
  selectedStateId: yup.string().required("El departamento es requerido"),
  selectedCityId: yup.string().required("La ciudad es requerido"),
  address: yup.string().required("La dirección es requerido"),
  socialStratumId: yup.string().required("El estrato es requerido"),
  catastralTypeId: yup.string(),
  niu: yup.string().required("El niu es requerido"),
  codeNetworkOperator: yup
    .string()
    .required("El código de cuenta en el operador de red es requerido"),
  catastralIdentifier: yup
    .string()
    .required("El identificador catastral es requerido"),
  longitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La longitud es requerida"),
  latitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La latitud es requerida"),
  altitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La altitud es requerida"),
  averageUsage: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("El consumo promedio es requerida"),
  numberOfPeriodsWithoutPenalty: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("El campo es requerido")
    .min(0, "El valor minimo debe de ser mayor o igual a 0")
    .max(3, "El valor máximo debe de ser menor o igual a 3"),
  serviceProviderId: yup.string().required("El campo es requerido"),
  electricitySupplyServiceContractType: yup.string(),
  installedCapacity: yup
    .string()
    .test("integer-number", "Debe de ingresar un número entero", (value) => {
      const format = /^\d+$/;
      if (format.test(value ?? "")) {
        return true;
      }
      return Number.isInteger(value);
    })
    .required("El campo es requerido"),
  plusCode: yup.string().required("El campo es requerido"),
  transformerCode: yup.string().nullable(),
  tensionLevelId: yup.string().required("El campo es requerido"),
  reactiveCounterM: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("El campo es requerido")
    .min(0, "El valor minimo debe de ser mayor o igual a 0")
    .max(12, "El valor máximo debe de ser menor o igual a 12"),
  reactiveCounterM1: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("El campo es requerido")
    .min(0, "El valor minimo debe de ser mayor o igual a 0")
    .max(12, "El valor máximo debe de ser menor o igual a 12"),
  reactiveCounterM6: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("El campo es requerido")
    .min(0, "El valor minimo debe de ser mayor o igual a 0")
    .max(12, "El valor máximo debe de ser menor o igual a 12"),
  marketerId: yup.string().required("El campo es requerido"),
  frontierId: yup.string().required("El campo es requerido"),
  frontierRegisterDate: yup.string().required("El campo es requerido"),
  daneCode: yup.string().required("El campo es requerido"),
  oldMarketerId: yup.string(),
  assetOwnershipId: yup.string().required("El campo es requerido"),
  marketingMarketId: yup.string().required("El campo es requerido"),
  subsistenceConsumptionId: yup.string().required("El campo es requerido"),
  portafolioRiskUserTypeId: yup.string().required("El campo es requerido"),
  qualityGroupId: yup.string(),
  zoneSuiId: yup.string().required("El campo es requerido"),
  specialConditionId: yup.string().required("El campo es requerido"),
  suiSocialStratumId: yup.string().required("El campo es requerido"),
  specialAreaTypeId: yup.string().required("El campo es requerido"),
  circuitCode: yup.string().nullable().required("El campo es requerido"),
  compensationHistory: yup.array().of(
    yup.object().shape({
      month: yup.string().required("El campo es requerido"),
      hour: yup.string().required("El campo es requerido"),
      time: yup.string().required("El campo es requerido"),
    })
  ),
  serviceStatusId: yup.string().required("El campo es requerido"),
  autogeneratorTypeId: yup.string().required("El campo es requerido"),
  frontierXmAutogeneratorCode: yup
    .string()
    .when(
      "autogeneratorTypeId",
      (autogeneratorTypeId: string, schema: yup.StringSchema) => {
        if (autogeneratorTypeId && autogeneratorTypeId != "3") {
          return schema.required("El campo es requerido");
        }
        return schema;
      }
    ),
  backUpAgreementPowerCapacity: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .when(
      "exportEnergyId",
      (exportEnergyId: string, schema: yup.NumberSchema) => {
        if (exportEnergyId && exportEnergyId != "2") {
          return schema.required("El campo es requerido");
        }
        return schema;
      }
    ),
  exportEnergyPowerCapacity: yup
    .string()
    .typeError("El campo debe de ser númerico")
    .when(
      "exportEnergyId",
      (exportEnergyId: string, schema: yup.StringSchema) => {
        if (exportEnergyId && exportEnergyId != "2") {
          return schema.required("El campo es requerido");
        }
        return schema;
      }
    ),
  // contract_file: yup
  //   .mixed()
  //   .test(
  //     "FILE_SIZE",
  //     "El archivo a subir es muy grande",
  //     (value?: File) => !value || (value && value.size <= 20000000)
  //   )
  //   .required("El campo es requerido"),
});
