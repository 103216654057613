import { initEnviroment } from "@enerbit/base";
import React, { ErrorInfo } from "react";
import ReactDOMClient from "react-dom/client";
import { AppProps } from "single-spa";
import singleSpaReact from "single-spa-react";
import App from "./App";

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: App,
  renderType: "createRoot",
  errorBoundary(err: Error, errInfo: ErrorInfo, props: AppProps) {
    return (
      <>
        Error name: {err.name}
        Message error: {err.message}
      </>
    );
  },
});

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  geopoliticsBaseUrl: process.env.REACT_APP_GEOPOLITICS_BASE_URL,
});

export const { bootstrap, mount, unmount } = lifecycles;
