import { EnerbitPagination, legalPersonsApi } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ElectricityMarketInfo } from "../../../models/electricity-market-info/ElectricityMarketInfo";
import { Retailer } from "../../../models/electricity-market-info/Retailer";
import { Market } from "../../../models/market/market";

export const getElectricityMarketInfo = createAsyncThunk(
  //action type string
  "[ELECTRICITY MARKET INFO] Get legal person",
  //callback function
  async (
    _payload,
    _thunkAPI
  ): Promise<EnerbitPagination<ElectricityMarketInfo>> => {
    const { data, status } = await legalPersonsApi.get(`/legal-person/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getRetailers = createAsyncThunk(
  //action type string
  "[ELECTRICITY MARKET INFO] Get Retailer",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<Retailer>> => {
    const { data, status } = await legalPersonsApi.get(
      `/electricity/retailer/`,
      {
        params: {
          size: 300,
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getMarkets = createAsyncThunk(
  //action type string
  "[ELECTRICITY MARKET INFO] Get markets DSO",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<Market>> => {
    const { data, status } = await legalPersonsApi.get(`/electricity/dso/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
