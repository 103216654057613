import { Box, Typography, enerbitColors, formatterPeso } from "@enerbit/base";
import {
  Charge,
  ChargeType,
  EnerbitElectricitySupplyServiceAgreement,
} from "../../../models/enerbit-electricity-supply-service-agreement/EnerbitElectricitySupplyServiceAgreement";

export type CardFijabitProps = {
  electricitySupplyServiceAgreement: EnerbitElectricitySupplyServiceAgreement;
  renderInputProps: React.HTMLAttributes<HTMLLIElement>;
};

export const CardFijabit = ({
  electricitySupplyServiceAgreement,
  renderInputProps,
}: CardFijabitProps) => {
  const _getDetailsEnerbitElectricitySupply = (
    enerbitElectricitySupply: EnerbitElectricitySupplyServiceAgreement
  ): [string, number][] => {
    const details: [string, number][] =
      enerbitElectricitySupply.conditions.charges.map((charge: Charge) => {
        if (charge.name == ChargeType.Variable) {
          const sum =
            charge.conditions.intervals?.reduce(
              (sum, current) => sum + current.price ?? 0,
              0
            ) ?? 0;
          return ["G+C:", sum];
        }
        return [
          "Herramientas tecnológicas (fijo):",
          charge.conditions.price ?? 0,
        ];
      });
    return details;
  };

  return (
    <Box
      key={electricitySupplyServiceAgreement.id}
      component="li"
      {...renderInputProps}
      sx={{
        flexDirection: "column",
        alignItems: "flex-start !important",
      }}
    >
      <Typography variant="body1" fontWeight="Bold">
        {electricitySupplyServiceAgreement.name}
      </Typography>
      <Box>
        {_getDetailsEnerbitElectricitySupply(
          electricitySupplyServiceAgreement
        ).map((tuple) => {
          return (
            <Typography
              key={tuple[0]}
              variant="body1"
              sx={{ color: enerbitColors.primary.main }}
            >
              {tuple[0]} {formatterPeso.format(tuple[1])}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};
