import { ExportEnergy, InitialHourCompensationsArray, InitialTimesCompensationsArray } from "@enerbit/base";
import moment from "moment";
import { AutoGeneratorType } from "../models/autogenerator-type/AutoGeneratorType";
import { CreateCregSubscriber } from "../models/electricity-supply-create/electricity-supply-create";
import { CreateServiceFormType } from "../models/form/CreateServiceTypeForm";
import { FormikType } from "./Utils";

export const shouldShowDependentAutoGeneratorTypeFields = (
  autogeneratorTypes: AutoGeneratorType[],
  valueToSearch: string
) => {
  const autogenerationType = autogeneratorTypes.find((value) => value.sui_code == valueToSearch);
  if (autogenerationType && autogenerationType.description.toLowerCase() != "no") {
    return true;
  }
  return false;
};

export const shouldShowDependentExportEnergyFields = (
  exportEnergy: ExportEnergy[],
  formik: FormikType<CreateServiceFormType>
) => {
  const exportEnergySelected = exportEnergy.find((value) => value.id == formik.values.exportEnergyId);
  if (exportEnergySelected && exportEnergySelected.sui_code == "1") {
    return true;
  }
  return false;
};

export const getInitialCompesatoryHistoricValues = () => {
  const historicData = [];
  let date = moment();
  for (let index = 0; index < 12; index++) {
    if (index != 0) {
      date = date.subtract(1, "months");
    }
    historicData.push({
      hour: "0",
      month: date.format("YYYY-MM"),
      time: "0",
    });
  }
  return historicData;
};

export const fillFieldsHistory = (hours: InitialHourCompensationsArray[], times: InitialTimesCompensationsArray[]) => {
  return hours.map((e, i) => {
    return {
      hour: String(e.number_of_hours),
      month: e.month,
      time: String(times[i].number_of_times),
    };
  });
};

export const deleteInputsUpdate = (body: CreateCregSubscriber) => {
  let newArray = { ...body };
  delete newArray.dane_code;
  delete newArray.frontier_xm_autogenerator_code;
  delete newArray.back_up_agreement;
  delete newArray.back_up_agreement_power_capacity;
  delete newArray.export_energy_id;
  delete newArray.export_energy_power_capacity;
  delete newArray.export_energy_generation_type_id;
  delete newArray.export_energy_start_datetime;
  return newArray;
};
