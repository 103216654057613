import { EnerbitPagination, Estate, ServiceProvider, SocialStratum, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EnerbitElectricitySupplyServiceAgreement } from "../../../models/enerbit-electricity-supply-service-agreement/EnerbitElectricitySupplyServiceAgreement";

export const getSocialStratums = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get SUI Social Stratums",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<SocialStratum>> => {
    const { data, status } = await api.get(`accounts/social-stratums/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getServiceProviders = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get Service Providers",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<ServiceProvider>> => {
    const { data, status } = await api.get(`accounts/providers/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getEnerbitElectricitySupplyServiceAgreements = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get Enerbit Electricicty Supply Service Agreements",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<EnerbitElectricitySupplyServiceAgreement> | null> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/enerbit-electricity-supply-services-agreements/`,
      {
        params: {
          size: 400,
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const createEstate = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Create Estate",
  //callback function
  async (payload: Estate, _thunkAPI): Promise<EnerbitPagination<Estate> | null> => {
    const { data, status } = await api.post(`accounts/estate`, payload);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
