export interface EnerbitElectricitySupplyServiceAgreement {
  name: string;
  description: string;
  service_agreement_type: ServiceAgreementType;
  conditions: Conditions;
  path_file: string;
  id: string;
}

export interface Conditions {
  cycle_begin: CycleBegin;
  cycle_end: CycleEnd;
  charges: Charge[];
}

export interface Charge {
  name: ChargeType;
  conditions: ChargeConditions;
}

export interface ChargeConditions {
  price?: number;
  intervals?: Interval[];
}

export interface Interval {
  start: string;
  duration: Duration;
  price: number;
}

export enum Duration {
  The24H = "24H",
}

export enum ChargeType {
  Fixed = "fixed",
  Variable = "variable",
}

export enum CycleBegin {
  Startm = "startm",
}

export enum CycleEnd {
  End = "end",
}

export enum ServiceAgreementType {
  Fijabit = "fijabit",
}
